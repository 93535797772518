<script>
import PrintArticleNew from './PrintArticleNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PrintArticleEdit',
  extends: PrintArticleNew,
  methods: {
    getPrintArticle () {
      this.$store.dispatch('printArticle/fetchOne', this.$route.params.id)
        .then(() => {
          this.printArticle = this.$store.getters['printArticle/detail']
          this.setSharedUsers()
          this.setPublication()
          this.setRubric()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('printArticle.error.required_fields'))
        return
      }
      this.$store.dispatch('printArticle/update', this.printArticle)
        .then(() => {
          if (this.$store.getters['printArticle/error'] === null) {
            this.getPrintArticle()
            NotifyService.setSuccessMessage(this.$t('printArticle.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setSharedUsers () {
      this.sharedUsers = this.users.filter(function (user) {
        return undefined !== this.printArticle.sharedUsers.find(function (userId) {
          return userId === user.id
        })
      }, this)
    },
    setPublication () {
      this.$store.dispatch('printPublication/fetchOne', this.printArticle.publication)
        .then(() => {
          this.publication = this.$store.getters['printPublication/detail']
          this.printTitleId = this.publication.printTitle
        })
    },
    setRubric () {
      this.$store.dispatch('rubric/fetchOne', this.printArticle.rubric)
        .then(() => {
          this.rubric = this.$store.getters['rubric/detail']
        })
    },
    showSaveAndAddNew () {
      return false
    }
  },
  created () {
    this.getPrintArticle()
  }
}
</script>
